import 'modern-normalize/modern-normalize.css';
import './src/styles/global.scss';
import './src/fonts/proximanova/proximanova.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCaretDown,
  faCaretUp,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faClock,
  faEnvelope,
  faLongArrowLeft,
  faLongArrowRight,
  faMapMarkerAlt,
  faMinus,
  faPhone,
  faPlus,
  faTimesCircle
} from '@fortawesome/pro-light-svg-icons';
import { faFacebookSquare, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const icons = [
  faCaretDown,
  faCaretUp,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faClock,
  faEnvelope,
  faLongArrowLeft,
  faLongArrowRight,
  faMapMarkerAlt,
  faMinus,
  faPhone,
  faPlus,
  faTimesCircle,
  faFacebookSquare,
  faWhatsapp
];

icons.map(icon => library.add(icon));
