// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("/home/shibainu/projects/oopstypo/client-websites/roomyr-revamp-2/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-book-js": () => import("/home/shibainu/projects/oopstypo/client-websites/roomyr-revamp-2/src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-checkout-success-js": () => import("/home/shibainu/projects/oopstypo/client-websites/roomyr-revamp-2/src/pages/checkout-success.js" /* webpackChunkName: "component---src-pages-checkout-success-js" */),
  "component---src-pages-en-juppuk-new-years-js": () => import("/home/shibainu/projects/oopstypo/client-websites/roomyr-revamp-2/src/pages/en/juppuk-new-years.js" /* webpackChunkName: "component---src-pages-en-juppuk-new-years-js" */),
  "component---src-pages-faq-js": () => import("/home/shibainu/projects/oopstypo/client-websites/roomyr-revamp-2/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-franchising-js": () => import("/home/shibainu/projects/oopstypo/client-websites/roomyr-revamp-2/src/pages/franchising.js" /* webpackChunkName: "component---src-pages-franchising-js" */),
  "component---src-pages-gallery-js": () => import("/home/shibainu/projects/oopstypo/client-websites/roomyr-revamp-2/src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("/home/shibainu/projects/oopstypo/client-websites/roomyr-revamp-2/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-juppuk-new-years-js": () => import("/home/shibainu/projects/oopstypo/client-websites/roomyr-revamp-2/src/pages/juppuk-new-years.js" /* webpackChunkName: "component---src-pages-juppuk-new-years-js" */),
  "component---src-pages-locations-js": () => import("/home/shibainu/projects/oopstypo/client-websites/roomyr-revamp-2/src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-privacy-policy-js": () => import("/home/shibainu/projects/oopstypo/client-websites/roomyr-revamp-2/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-promo-js": () => import("/home/shibainu/projects/oopstypo/client-websites/roomyr-revamp-2/src/pages/promo.js" /* webpackChunkName: "component---src-pages-promo-js" */),
  "component---src-pages-retrieve-js": () => import("/home/shibainu/projects/oopstypo/client-websites/roomyr-revamp-2/src/pages/retrieve.js" /* webpackChunkName: "component---src-pages-retrieve-js" */),
  "component---src-pages-services-js": () => import("/home/shibainu/projects/oopstypo/client-websites/roomyr-revamp-2/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-terms-conditions-js": () => import("/home/shibainu/projects/oopstypo/client-websites/roomyr-revamp-2/src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-zh-juppuk-new-years-js": () => import("/home/shibainu/projects/oopstypo/client-websites/roomyr-revamp-2/src/pages/zh/juppuk-new-years.js" /* webpackChunkName: "component---src-pages-zh-juppuk-new-years-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/shibainu/projects/oopstypo/client-websites/roomyr-revamp-2/.cache/data.json")

