module.exports = [{
      plugin: require('/home/shibainu/projects/oopstypo/client-websites/roomyr-revamp-2/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-130442107-1","head":false},
    },{
      plugin: require('/home/shibainu/projects/oopstypo/client-websites/roomyr-revamp-2/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"829893314603624"},
    },{
      plugin: require('/home/shibainu/projects/oopstypo/client-websites/roomyr-revamp-2/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
